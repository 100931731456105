import { Typography, Card } from '@material-ui/core';
import React from 'react';

type PreferencePanelProps = {
  header: React.ReactNode;
  body: React.ReactNode;
  action?: React.ReactNode;
  dataSpec?: string;
};

export function PreferencePanel({ header, body, action, dataSpec }: PreferencePanelProps) {
  return (
    <div className="mb-24">
      <Typography className="flex items-center" variant="h3">
        {header}
      </Typography>
      <Card className="p-10 mt-8" data-spec={dataSpec}>
        <div className="flex">{body}</div>
      </Card>
      {action}
    </div>
  );
}

import React from 'react';
import { Typography, Divider, Button } from '@material-ui/core';
import { ChevronRight, Check } from '@material-ui/icons';
import { twMerge } from 'tailwind-merge';
import { format } from '@skyslope/utils';
import { PreferencePanel } from './PreferencePanel';
import accountBalanceWallet from '../../images/account_balance_wallet.svg';
import { useCreateBillingPortalSession, useGetStripeSubscription } from '../../hooks/accounts-api';

export function BillingManagementPanel() {
  const { data: subscription } = useGetStripeSubscription();
  const { mutateAsync: createBillingPortalSession } = useCreateBillingPortalSession();

  if (!subscription || !subscription?.subscriptionId || !subscription?.customerId) return null;

  const activeDiscount = subscription.discount ?? 0;
  const basePrice = (subscription.price ?? 0) / 100;
  const priceWithDiscount = (basePrice * (1 - activeDiscount / 100)).toFixed(2);

  const isActive = subscription.status === 'active';
  const willBeCancelled = subscription.cancelAtPeriodEnd;
  const statusLabel =
    isActive && willBeCancelled
      ? // date-fns format as January 20, 1970
        `Cancels ${format.date(subscription.cancelAt, 'MMMM d, yyyy')}`
      : subscription.status?.charAt(0).toUpperCase() + subscription.status?.slice(1);

  const paymentLabel = !willBeCancelled
    ? 'Next Payment'
    : subscription.status === 'past_due'
    ? 'Payment Past Due'
    : 'Last Payment';

  const paymentDate = format.date(subscription.currentPeriodEnd, 'MMMM d, yyyy');

  async function manageSubscription(isCancel?: boolean) {
    const session = await createBillingPortalSession({ customerId: subscription?.customerId ?? '' });

    if (isCancel) {
      window.open(
        `${session.result}/subscriptions/${subscription?.subscriptionId}/cancel`,
        '_blank',
        'noopener,noreferrer'
      );
      return;
    }

    window.open(session.result, '_blank', 'noopener,noreferrer');
  }

  function determineSubscriptionType() {
    const oneMonthInSeconds = 30 * 24 * 60 * 60;
    const oneYearInSeconds = 365 * 24 * 60 * 60;

    const start = new Date(subscription?.currentPeriodStart ?? '').getTime() / 1000;
    const end = new Date(subscription?.currentPeriodEnd ?? '').getTime() / 1000;

    const delta = end - start;

    if (Math.abs(delta - oneYearInSeconds) < oneMonthInSeconds / 2) return 'Annual Subscription';
    if (Math.abs(delta - oneMonthInSeconds) < oneMonthInSeconds / 2) return 'Monthly Subscription';

    return 'Subscription';
  }

  return (
    <PreferencePanel
      header={
        <>
          <img className="w-16 h-16 mr-4" src={accountBalanceWallet} alt="title logo" />
          Billing Management
        </>
      }
      body={
        <>
          <section className="relative flex flex-col justify-between basis-3/5">
            <div className="space-y-10">
              <div className="space-y-4">
                <span className="flex gap-2">
                  <Typography variant="body2">{determineSubscriptionType()}</Typography>
                  <Typography
                    className={twMerge(
                      'rounded-md px-2 text-sm font-semibold self-center',
                      isActive && !willBeCancelled ? 'bg-[#D5FFE6]' : 'bg-[#FEF0F2]'
                    )}
                  >
                    {statusLabel}
                  </Typography>
                </span>

                <Typography className="text-sm">
                  For more information on automatic renewal and cancellation terms, please{' '}
                  <a
                    href="https://skyslope.com/digisign-sales-order/"
                    className="font-semibold text-blue-800 no-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    click here.
                  </a>
                </Typography>
              </div>

              <span className="flex gap-6">
                {subscription.status !== 'canceled' && !willBeCancelled && (
                  <>
                    <div>
                      <Typography variant="body2" className="text-sm">
                        {paymentDate}
                      </Typography>
                      <Typography className="flex items-center gap-2 text-sm">{paymentLabel}</Typography>
                    </div>

                    <Divider orientation="vertical" flexItem />

                    <div>
                      <Typography variant="body2" className="text-sm">
                        ${priceWithDiscount}
                      </Typography>
                      <Typography className="flex items-center gap-2 text-sm">Billing Amount</Typography>
                    </div>
                  </>
                )}
                <Divider orientation="vertical" flexItem />
                <div className="space-y-1">
                  <Typography variant="body2" className="text-sm">
                    {subscription.email}
                  </Typography>
                  <Button className="p-0 text-sm text-blue-800" onClick={() => manageSubscription(false)}>
                    Manage Subscription <ChevronRight className="text-sm" />
                  </Button>
                </div>
              </span>
            </div>
            {isActive && !willBeCancelled && (
              <Button
                className="self-start p-0 text-sm font-semibold text-red-300"
                onClick={() => manageSubscription(true)}
              >
                Cancel Subscription
              </Button>
            )}
          </section>
          <section className="space-y-4">
            <Typography variant="body2">Features</Typography>
            <div className="flex flex-col space-y-3 grow shrink-0">
              <FeatureListItem>Unlimited envelopes per month</FeatureListItem>
              <FeatureListItem> 24/7 tech support</FeatureListItem>
              <FeatureListItem>Free Strikethrough access</FeatureListItem>
              <FeatureListItem>Synced to SkySlope Transactions</FeatureListItem>
              <FeatureListItem>45% off the price of the top e-signature competitor</FeatureListItem>
            </div>
          </section>
        </>
      }
    />
  );
}

function FeatureListItem({ children }: { children: React.ReactNode }) {
  return (
    <Typography className="flex items-center gap-2 text-sm">
      <Check className="text-lg text-green-400" />
      {children}
    </Typography>
  );
}

import { UseQueryOptions, useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { accountApi } from '@digisign3-ui/sender/src/lib/api/account';
import { IUserMemberships, Trial } from '@digisign3-ui/sender/src/store/pageFrame/types';
import { successToast, errorToast } from '../../store/pageFrame/actions';

export function useGetMemberships(options?: UseQueryOptions<IUserMemberships>) {
  return useQuery(
    ['get-user-memberships'],
    async () => {
      const memberships = await accountApi.getUsersMemberships();
      return memberships;
    },
    options
  );
}

export function useGetTrial(options?: UseQueryOptions<Trial | undefined>) {
  return useQuery(
    ['get-trial'],
    async () => {
      const res = await accountApi.getTrial();

      return res;
    },
    options
  );
}

export function useCreateTrial() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => accountApi.createTrial(),
    onSuccess() {
      queryClient.invalidateQueries(['has-ds3-access']);
    },
  });
}

export function useExtendTrial() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => accountApi.extendTrial(),
    onSuccess() {
      queryClient.invalidateQueries(['has-ds3-access']);
    },
  });
}

export function useUpdateExtendedTrialEnvelopes() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await accountApi.updateExtendedTrialEnvelopes();
    },
    onSuccess() {
      queryClient.invalidateQueries(['has-ds3-access']);
    },
  });
}

export function useGetStripeSubscription() {
  return useQuery(['get-user-subscription'], () => accountApi.getStripeSubscription().then((res) => res?.result), {
    onSuccess() {
      const params = new URLSearchParams(window.location.search);
      const fromPaymentSuccess = params.has('paymentSuccess');

      if (fromPaymentSuccess) {
        successToast('Your subscription has been successfully updated!');
      }
    },
    onError() {
      errorToast('We were unable to validate your subscription, please try again or reach out to support');
    },
  });
}

export function useCreateBillingPortalSession() {
  return useMutation({
    mutationFn: (args: { customerId: string }) => accountApi.createBillingPortalSession(args),
  });
}

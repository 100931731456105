import React from 'react';
import { FormControl, RadioGroup, colors, FormControlLabel, Radio, Button } from '@material-ui/core';
import { twMerge } from 'tailwind-merge';
import { PreferencePanelData, PreferencePanelOption } from '../../lib/preferencePanels';
import { PreferencePanel } from './PreferencePanel';

type PreferenceRadioPanelProps = {
  data: PreferencePanelData;
  initialSelection: string;
  options: PreferencePanelOption[];
  onSave: (val: string) => void;
  dataSpec?: string;
};

export function PreferenceRadioPanel(props: PreferenceRadioPanelProps) {
  const { data, options, onSave, dataSpec } = props;

  const [hoverActive, setHoverActive] = React.useState(false);
  const [currentSelection, setCurrentSelection] = React.useState(props.initialSelection);

  const isButtonDisabled = currentSelection === props.initialSelection;
  const setGifOrImage = (option: PreferencePanelOption) => (hoverActive ? option.onHoverImage : option.image);
  const initialOption = options.find((o) => o.value === currentSelection) || options.find((o) => o.isDefault);
  const gif = setGifOrImage(initialOption!);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCurrentSelection((e.target as HTMLInputElement).value);
  }

  return (
    <PreferencePanel
      header={
        <>
          <img className="w-16 h-16 mr-4" src={data.titleImage} alt="title logo" />
          {data.title}
        </>
      }
      body={
        <>
          <div className="flex-1 mr-8">
            <img
              className="w-[486px] border h-[294px] border-solid border-[#CAD5E0]"
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
              src={gif}
              alt=""
            />
          </div>
          <div className="flex-1 ml-28">
            <FormControl>
              <RadioGroup
                aria-label={data.ariaLabel}
                value={currentSelection}
                onChange={handleChange}
                data-spec="radio-control"
              >
                {options.map((o: PreferencePanelOption, index) => {
                  return (
                    <FormControlLabel
                      className={twMerge(
                        `items-start px-6 py-7 hover:bg-[${colors.blue[50]}]`,
                        index > 0 && 'border-t border-solid border-grey-300'
                      )}
                      value={o.value}
                      control={
                        <Radio
                          classes={{ checked: 'text-blue-800' }}
                          color="primary"
                          style={{ color: colors.grey[600] }}
                        />
                      }
                      label={<RadioLabel option={o} />}
                      data-spec={o.dataSpec}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </>
      }
      action={
        <Button
          className="flex float-right mt-6"
          variant="contained"
          color="primary"
          disabled={isButtonDisabled}
          onClick={() => onSave(currentSelection)}
          data-spec={data.dataSpec}
        >
          Save
        </Button>
      }
      dataSpec={dataSpec}
    />
  );
}

function RadioLabel(props: { option: PreferencePanelOption }) {
  const { option } = props;
  const mainLabelText = option.isDefault ? (
    <>
      <span className="font-bold text-[19px] tracking-[.16px]">{option.label} </span>
      <span className="text-[19px]">(default)</span>
    </>
  ) : (
    <span className="font-bold text-[19px] tracking-[.16px]">{option.label}</span>
  );
  return (
    <div>
      {mainLabelText}
      <br />
      <span className="text-['16px'] whitespace-normal break-words">{option.subLabel}</span>
    </div>
  );
}

import React from 'react';
import { HeaderContext } from '../context/header-context';
import { IRootState } from '../store';
import { useShallowSelector, useDispatch } from '../lib/reduxHooks';
import { ISettings } from '../store/pageFrame/types';
import ManagementHeader from '../components/common/ManagementHeader';
import FooterLinks from '../components/common/FooterLinks';
import * as actions from '../store/pageFrame/actions';
import Spinner from '../components/common/Spinner';
import {
  signaturePreference,
  signaturePreferenceOptions,
  keepBlocksSelectedPreference,
  keepBlockSelectedOptions,
  emailDocsAsAttachmentPreferenceOptions,
  emailDocsAsAttachmentPreference,
  dateFormatPreference,
  dateFormatPreferenceOptions,
  automatedEmailsReminderPreference,
  automatedEmailsReminderPreferenceOptions,
} from '../lib/preferencePanels';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import { PreferenceRadioPanel, BillingManagementPanel } from '../components/userPreferences';

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  signerGroups: state.senderBuild.signingGroups,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  contacts: state.senderBuild.recipientsModal.contacts,
});

interface IState {
  settings: ISettings;
  settingsFetched: boolean;
}

function UserPreferences({ flags }: { flags: LaunchDarklyFlags }) {
  const dispatch = useDispatch();
  const { settings, settingsFetched }: IState = useShallowSelector(selector);
  const headerContext = React.useContext(HeaderContext);
  const [loading, setLoading] = React.useState(true);
  const showEmailSettingsPreference = flags && flags['enable-email-settings-preference'];
  const showDateFormatPreference = flags && flags['enable-date-stamping-preferences'];
  const showAutomatedRemindersFlag = flags && flags['automated-reminders'];

  React.useEffect(() => {
    if (settingsFetched) {
      setLoading(false);
    }

    headerContext.setContent(
      <ManagementHeader resource="Preference" flags={flags} userOrigin={settings?.account.userOrigin} />
    );
  }, [settingsFetched]);

  if (loading) return <Spinner />;

  return (
    <div className="flex flex-col max-w-[1200px]">
      <PreferenceRadioPanel
        data={signaturePreference}
        initialSelection={settings.user.autoStampDateTime.preference || 'None'}
        options={signaturePreferenceOptions}
        onSave={(val: string) => {
          dispatch(
            actions.updateUserSettings({
              autoStampDateTime: {
                preference: val,
              },
            })
          );
        }}
      />
      {showDateFormatPreference && (
        <PreferenceRadioPanel
          data={dateFormatPreference}
          initialSelection={settings.user.dateFormat?.preference || 'MM/DD/YYYY'}
          options={dateFormatPreferenceOptions}
          onSave={(val: string) => {
            dispatch(
              actions.updateUserSettings({
                dateFormat: {
                  preference: val,
                },
              })
            );
          }}
        />
      )}
      <PreferenceRadioPanel
        data={keepBlocksSelectedPreference}
        initialSelection={(settings.user.keepBlocksSelected || false).toString()}
        options={keepBlockSelectedOptions}
        onSave={(val: string) => {
          dispatch(
            actions.updateUserSettings({
              keepBlocksSelected: val === 'true',
            })
          );
        }}
      />
      {showEmailSettingsPreference && (
        <PreferenceRadioPanel
          dataSpec="attachmentSettings"
          data={emailDocsAsAttachmentPreference}
          initialSelection={(settings.user.emailDocsAsAttachment !== undefined
            ? settings.user.emailDocsAsAttachment
            : true
          ).toString()}
          options={emailDocsAsAttachmentPreferenceOptions}
          onSave={(val: string) => {
            dispatch(
              actions.updateUserSettings({
                emailDocsAsAttachment: val === 'true',
              })
            );
          }}
        />
      )}
      {showAutomatedRemindersFlag && (
        <PreferenceRadioPanel
          dataSpec={automatedEmailsReminderPreference.dataSpec}
          data={automatedEmailsReminderPreference}
          initialSelection={(settings.user.automatedReminders?.enabled !== undefined
            ? settings.user.automatedReminders.enabled
            : true
          ).toString()}
          options={automatedEmailsReminderPreferenceOptions}
          onSave={(val: string) => {
            dispatch(
              actions.updateUserSettings({
                automatedReminders: {
                  enabled: val === 'true',
                },
              })
            );
          }}
        />
      )}
      <BillingManagementPanel />
      <div className="absolute bottom-[40px] left-[100px] right-[100px]">
        <FooterLinks showSocialIcons customMarginTop="0px" />
      </div>
    </div>
  );
}

export default withLaunchDarkly(UserPreferences);
